<template>
	<div>
		<export-excel
			class="btn btn-outline-success"
			:data="data"
			:name="name"
		>
			<b-icon icon="file-excel" />EXPORTAR EXCEL
		</export-excel>
	</div>
</template>

<script>
import ExportExcel from "vue-excel-export"
import Vue from "vue"

Vue.use(ExportExcel)

export default {
	components: {
		ExportExcel
	},
	props: {
		data: {
			type: Array,
			default: () => []
		},
		name: {
			type: String,
			default: ""
		}
	}
}
</script>

<style lang="scss" scoped>
.btn {
	display: block;
	width: 100%;
	margin-bottom: 1rem !important;
	border-radius: 0.25rem !important;
	font-weight: bold;
}

.btn.btn-outline-success {
	cursor: pointer;
}

.btn i {
	margin-right: 0.5rem !important;
}
</style>
